
.scroll-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 52px;
  height: 100px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border-radius: 12px 0px 0px 12px;
  cursor: pointer;

  &--left {
    left: 0;
    right: unset;
    border-radius: 0px 12px 12px 0px;
  }
}

.conflicts-table-outer {
  position: relative;
  width: 100%;
}

.conflicts-table {
  position: relative;
  width: 100%;
  height: 400px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  overflow: hidden;
  padding-left: 380px;

  &__absolute-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: #FFFFFF;
    box-shadow: 6px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px 0px 0px 12px;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__body-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__body {
    display: flex;
    width: 100%;
    overflow: auto;
    position: relative;
    height: 100%;
  }
  &__column {
    flex-shrink: 0;
    width: 380px;
    display: flex;
    flex-direction: column;
  }
  &__cell {
    flex-shrink: 0;
    height: 72px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 36px;
    &--head {
      padding: 12px 24px;
      height: 48px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    &--selected {
      background: rgba(0, 0, 0, 0.08);
    }
    &--not-selected {
      background: rgba(0, 0, 0, 0.04);
    }
    &--merged {
      background: #F7F6FF;
    }
  }
  &__radio-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
}