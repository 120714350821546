.switch-button {
  border: 1px solid #155FFF;
  letter-spacing: 1px;
  padding-right: 60px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  color: #155FFF;
  overflow: hidden;
  width: 120px;
  height: 40px;

  .switch-button-text-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .switch-button-span {
    display: inline-block;
    width: 60px;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 2;
    bottom: 0;
    left: 0;
    top: 0;

    &:checked {
      & + .switch-button-label {
        color: #155FFF;
        &:before {
          transform: translateX(60px);
          transition: transform 100ms linear;
        }

        & + div span:last-child {
          color: #fff;
        }
        & + div span:first-child {
          color: #155FFF;
        }
      }
    }

    & + .switch-button-label {
      user-select: none;
      pointer-events: none;
      justify-content: center;
      position: relative;
      display: flex;
      height: 100%;

      & + div span:first-child {
        color: #fff;
      }

      &:before {
        content: "";
        background: #2E7D32;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(0);
        transition: transform 100ms;
      }

      .switch-button-label-span {
        position: relative;
        align-self: center;
      }
    }
  }
}
