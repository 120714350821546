:root {
  --fc-border-color: rgba(0, 0, 0, 0.12);
  --fc-today-bg-color: rgba(0, 0, 0, 0.03);
  --fc-more-link-bg-color: rgba(2, 136, 209, 0.12);
  --fc-event-border-color: none;
  --fc-event-bg-color: rgba(2, 136, 209, 0.12);
  --fc-event-text-color: #000000de;
  --fc-page-bg-color: #fff;
}

.fc-event-past {
  --fc-event-bg-color: rgba(2, 136, 209, 0.04);
}

.dark {
  --fc-page-bg-color: #121212;
  --fc-more-link-bg-color: #1F495E;
  --fc-event-text-color: #FFFFFF;
  --fc-border-color: rgba(255, 255, 255, 0.12);
  --fc-today-bg-color: rgba(255, 255, 255, 0.12);
  --fc-event-bg-color: #1F495E;
  --fc-event-border-color: rgba(2, 136, 209, 0.08);
  .fc-event-past {
    --fc-event-bg-color: rgba(2, 136, 209, 0.12);
  }
}



html, body, #root {
  height: 100%;
  overflow: hidden;
}

main {
  height: 100%;
  overflow: scroll;
  background-color: #121212;
  position: relative;
}

.dark {
  &, * {
    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #787878;
      border-radius: 12px;
      border: 2px solid #383838;
    }

    &::-webkit-scrollbar-track {
      background: #383838;
    }

    &::-webkit-scrollbar-corner {
      background: #787878;
    }
  }
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-more-link {
  box-shadow: none!important;
}

.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
  border: none;
  height: 60px;
  width: 60px;
  vertical-align: unset;
  position: relative;
}

.fc-timegrid-body {
  padding-top: 9px;
}

.fc-timegrid-slot-label-frame.fc-scrollgrid-shrink-frame {
  padding: 0 12px;
}

.fc-scrollgrid-sync-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-col-header-cell-cushion {
  margin-top: 0;
}

div.fc-view-harness.fc-view-harness-active > div > table > thead > tr > th > div > div > table > thead > tr > th.fc-timegrid-axis,
.fc-col-header-cell.fc-day.fc-day-sun.fc-day-future,
.fc-col-header-cell.fc-day.fc-day-sat.fc-day-future,
.fc-col-header-cell.fc-day.fc-day-fri.fc-day-today,
.fc-col-header-cell.fc-day.fc-day-thu.fc-day-past,
.fc-col-header-cell.fc-day.fc-day-wed.fc-day-past,
.fc-col-header-cell.fc-day.fc-day-tue.fc-day-past,
.fc-col-header-cell.fc-day.fc-day-mon.fc-day-past {
  border-bottom: none;
}

.fc-scrollgrid.fc-scrollgrid-liquid {
  border-radius: 12px;
  overflow: hidden;
}

.fc-scroller-harness {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  flex-direction: column;
  overflow: hidden;
}

div.fc-daygrid-day-top {
  align-self: flex-end;
}

div.fc-daygrid-day-events {
  width: 100%;
}


.SnackbarContainer-root {
  flex-direction: column-reverse !important;
}

.fc-col-header {
  overflow: hidden;
}

.fc-popover-body {
  max-height: 400px;
  overflow-y: auto;
}

.event-transparent {
  background-color: transparent !important;
}


.view-btn {
  &.active {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #2E7D32;
      bottom: 0;
    }
  }
}

::-webkit-scrollbar {
  height: 12px; /* Высота ползунка */
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Цвет ползунка */
  border-radius: 12px; /* Радиус углов ползунка */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Цвет ползунка при наведении */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Цвет фона трека */
  border-radius:12px; /* Радиус углов трека */
}

/* Для Firefox */
* {
  scrollbar-width: thin; /* Толщина ползунка */
  scrollbar-color: #888 #f1f1f1; /* Цвет ползунка и фона трека */
}

/* Для новых версий браузеров, поддерживающих стилизацию через псевдоэлементы */
::-webkit-scrollbar-horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb-horizontal {
  background-color: #888;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb-horizontal:hover {
  background-color: #555;
}

::-webkit-scrollbar-track-horizontal {
  background: #f1f1f1;
  border-radius: 12px;
}
/**
 * Fully expanded "now" indicator line
 * @see https://github.com/fullcalendar/fullcalendar/issues/4609#issuecomment-1010706978
 */

.fc-timeGridWeek-view .fc-timegrid-now-indicator-container {
  overflow: visible !important;
}
.fc-timeGridWeek-view .fc-timegrid-now-indicator-line {
  width: calc(700% + 6px) !important;
}

/**
 * The use of nth-child is to address the day columns depending on their order.
 *
 * - The 1st column does not correspond to any day, but it's the column that shows the timegrid
 *   timeline.
 * - The 2nd column corresponds to the 1st day of the week view. This is the one with 0% below.
 * - We continue with each subsequent column, incrementing its percentage in 1/7 each time.
 *
 * By addressing day columns by their order, instead of by their weekday class name
 * (e.g. .fc-day-fri) we guarantee that this solution works regardless of what the first day of the
 * week is.
 */
.fc-timeGridWeek-view .fc-day:nth-child(2) .fc-timegrid-now-indicator-line {
  --fc-now-indicator-shift: 0%;
}
.fc-timeGridWeek-view .fc-day:nth-child(3) .fc-timegrid-now-indicator-line {
  --fc-now-indicator-shift: 14.29%;
}
.fc-timeGridWeek-view .fc-day:nth-child(4) .fc-timegrid-now-indicator-line {
  --fc-now-indicator-shift: 28.57%;
}
.fc-timeGridWeek-view .fc-day:nth-child(5) .fc-timegrid-now-indicator-line {
  --fc-now-indicator-shift: 42.86%;
}
.fc-timeGridWeek-view .fc-day:nth-child(6) .fc-timegrid-now-indicator-line {
  --fc-now-indicator-shift: 57.14%;
}
.fc-timeGridWeek-view .fc-day:nth-child(7) .fc-timegrid-now-indicator-line {
  --fc-now-indicator-shift: 71.43%;
}
.fc-timeGridWeek-view .fc-day:nth-child(8) .fc-timegrid-now-indicator-line {
  --fc-now-indicator-shift: 85.71%;
}

.fc-timeGridWeek-view .fc-day .fc-timegrid-now-indicator-line {
  transform: translateX(calc(-1 * var(--fc-now-indicator-shift)));
}
.fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before {
  position: absolute;
  display: block;
  content: '';
  margin-top: -7px;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  border: 1px solid var(--reactist-bg-default);
  background-color: red;
  left: calc(var(--fc-now-indicator-shift) - 3px);
}

/* Стили для точки */
.custom-now-indicator {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  z-index: 10;
  top: 0; /* начальная позиция, будет изменена скриптом */
}


.fc-more-link  {
  height: 30px;
  bottom: 0!important;
  background: transparent!important;
  color: #2E7D32!important;
}


.fillingCourtInfo {
  margin-left: 30px;
  li {
    list-style: inside;
    font-size: 14px;
    color: #000;
  }
}


.EntityDetailsSettingContent {
  .MuiTypography-root {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiInputBase-root {
    min-width: auto;
  }
}

.filesInfo-section {
  &__chips {

    margin-top: 20px;
    grid-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.filesInfo {
  display: flex;
  align-items: center;
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 12px;
    margin-left: 10px;
    &:first-child{
      margin-left: 0;
    }
  }
  &__block {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
}
